








































import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import PromoBaseForm from '@/components/forms/promo/PromoBaseForm.vue'
import Tag from '@/components/_uikit/Tag.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import AddPromocodesModal from '@/components/modals/promocodes/AddPromocodesModal.vue'
// interfaces
import {
  NameValueResource,
  PromoCodeType,
  PromotionCodeData,
  PromotionCodeResource,
  PromotionData, UserShortResource,
} from '@/store/types'
import DictionaryModule from '@/store/modules/dictionary'

@Component({
  components: {
    AddPromocodesModal,
    PromoBaseForm,
    Tag,
    UserSocialCard,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class PromoCodesTable extends NotifyMixin {
  @Prop({ default: null })
  private form!: PromotionData

  @Prop({ default: null })
  private codes!: PromotionCodeResource[]

  private isShowAddPromocodes = false

  private get promotionCodeTypes () {
    return DictionaryModule.promotionCodeTypes
  }

  private get headers () {
    return [
      { text: 'Код', value: 'code' },
      { text: 'Тип', value: 'type' },
      { text: 'Пользователь', value: 'user' },
      { text: 'Статус', value: 'status' },
      { cellClass: 'cell-width-5', text: '', value: 'delete' },
    ]
  }

  private codePeople (item: PromotionCodeData): UserShortResource[] {
    const index = this.codes.findIndex((code: PromotionCodeResource) => code.code === item.code)
    return index >= 0 ? this.codes[index].users : []
  }

  private getCodeType (type: PromoCodeType) {
    const codeType = this.promotionCodeTypes.find((code: NameValueResource) => code.value === type)
    return codeType ? codeType.name : ''
  }

  private handleAddCode (codes: PromotionCodeData[]) {
    if (this.form.code.length) {
      this.form.codes = [...this.form.codes, ...codes]
    } else {
      this.notifyError('Для генерации уникального кода, необходимо заполнить поле "Код" ')
    }
  }

  private handleDeleteCode(code: string) {
    if (this.form.code.length) {
      this.form.codes.splice(this.form.codes.findIndex(item => item.code === code), 1)
    }
  }
}
