




























































































































import { unionBy } from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Prop, Watch } from 'vue-property-decorator'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import DateInput from '@/components/_uikit/controls/DateInput.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import SearchCourseModal from '@/components/modals/courses/SearchCourseModal.vue'
import SearchPackageModal from '@/components/modals/packages/SearchPackageModal.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import Tag from '@/components/_uikit/Tag.vue'
import DictionaryModule from '@/store/modules/dictionary'
import PromoCodesTable from '@/components/forms/promo/PromoCodesTable.vue'
// interfaces
import {
  CourseShortResource,
  PackageShortResource,
  PromoCodeType,
  PromotionData,
  PromotionDiscountType,
  PromotionLargeResource,
  PromotionProductType,
} from '@/store/types'

@Component({
  components: {
    CourseSearch,
    DateInput,
    PromoCodesTable,
    SearchCourseModal,
    SearchPackageModal,
    Select,
    SwitchInput,
    Tag,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class PromoBaseForm extends NotifyMixin {
  @Prop({ default: null })
  private promotion!: PromotionLargeResource

  private showSearchPackageModal = false
  private showSearchCourseModal = false

  private packages: PackageShortResource[] = []
  private courses: CourseShortResource[] = []

  private form: PromotionData = {
    code: '',
    codes: [],
    discount: '' as unknown as number,
    discountType: PromotionDiscountType.PERCENT,
    name: '',
    productIds: [],
    productType: PromotionProductType.ALL,
  }

  private get promoTypes () {
    return DictionaryModule.promotionDiscountTypes
  }

  private get promoTypesProject () {
    return DictionaryModule.promotionProductTypes
  }

  private created () {
    if (this.promotion) {
      this.packages = this.promotion.packages
      this.courses = this.promotion.courses

      this.form = {
        ...this.form,
        code: this.promotion.code,
        codes: this.promotion.codes.map((item) => ({
          code: item.code,
          id: item.id,
          status: item.status,
          type: item.type.value as PromoCodeType,
        })),
        discount: this.promotion.discount,
        discountType: this.promotion.discountType.value as PromotionDiscountType,
        endAt: this.promotion.endAt,
        name: this.promotion.name,
        productType: this.promotion.productType.value as PromotionProductType,
        startAt: this.promotion.startAt,
      }

      this.$nextTick(() => {
        this.form.productIds = this.packages.length !== 0
          ? this.packages.map((item) => item.id)
          : this.courses.map((item) => item.id)
      })
    }
  }

  private handleAddPackages (packages: PackageShortResource[]) {
    this.packages = unionBy(this.packages, packages, 'id')
    this.form.productIds.push(...packages.map((item: PackageShortResource) => item.id))
  }

  private handleAddCourses (courses: CourseShortResource[]) {
    this.courses = unionBy(this.courses, courses, 'id')
    this.form.productIds.push(...courses.map((item: CourseShortResource) => item.id))
  }

  private handleDeletePackage (index: number) {
    this.packages.splice(index, 1)
    this.form.productIds.splice(index, 1)
  }

  private handleDeleteCourse (index: number) {
    this.courses.splice(index, 1)
    this.form.productIds.splice(index, 1)
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.$emit('submit', this.form)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  @Watch('form.productType')
  private watchProductType (value: string) {
    this.form.productIds = []
    if (value !== PromotionProductType.PACKAGES) {
      this.packages = []
    }
    if (value !== PromotionProductType.COURSES) {
      this.courses = []
    }
  }
}
