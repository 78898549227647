

































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
// libraries
import randomstring from 'randomstring'

// components
import Dialog from '@/components/modals/Dialog.vue'
import ModalWrapper from '@/components/modals/ModalWrapper.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// store
import DictionaryModule from '@/store/modules/dictionary'
// interfaces
import { PromoCodeType, PromotionCodeData } from '@/store/types'

@Component({
  components: {
    Dialog,
    ModalWrapper,
    Select,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddPromocodesModal extends Vue {
  @Prop({ required: true })
  private visible!: boolean

  @Prop({ required: true })
  private baseCode!: string

  private title = 'Создание кодов'

  private promocode: { amount: number, type: PromoCodeType } = {
    amount: 0,
    type: PromoCodeType.DISPOSABLE,
  }

  private promocodeTypes = DictionaryModule.promotionCodeTypes

  private handleModalClose() {
    this.$emit('close')
  }

  private _generateCode (baseCode: string): PromotionCodeData {
    return {
      code: `${baseCode}-${randomstring.generate(5)}`,
      type: this.promocode.type,
    }
  }

  private handleGenerateCodes() {
    let codes: PromotionCodeData[] = []

    for (let i = 0; i < this.promocode.amount; i++) {
      codes.push(this._generateCode(this.baseCode))
    }

    this.$emit('codes', codes)
    this.$emit('close')
  }

}
